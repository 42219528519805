
import "./login.css";
import { FaUser } from "react-icons/fa";
import { RiLockPasswordLine } from "react-icons/ri";
import { SiGnuprivacyguard } from "react-icons/si";
import { MdEmail } from "react-icons/md";
import { FaRegEye, FaRegEyeSlash,  FaMobileAlt } from "react-icons/fa";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import  * as u  from '../utils/Common';

const Signin = ({ formmode = '' }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const [visitorID, setVisitorID] = useState('');


  useEffect(() => {
    // Load the FingerprintJS agent
    const loadFingerprint = async () => {
      try {
        const fp = await FingerprintJS.load(); // Load the FingerprintJS agent
        const result = await fp.get(); // Generate the fingerprint
        setVisitorID(result.visitorId); // Extract the unique visitor ID
      } catch (error) {
        console.error('Error generating fingerprint:', error);
      }
    };

    loadFingerprint();
  }, []);

  const [password, setpassword] = useState(false);
  const [confirm, setconfirm] = useState(false);
  const [existingUser, setMsg] = useState(false);
  const [getinputdata, setgetinputdata] = useState({ uname: "", email: "", password: "", confirm: "", })
  const [inputerror, setinputerror] = useState({ uname: false, email: false, password: false, confirm: false })

  const usernameRegex = /^[0-9A-Za-z]{6,16}$/;
  const passwordRegex = /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[!@#$%*_.]).{6,16}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  function passwordhandler() {
    setpassword(!password);
  }

  function confirmpasswordhandler() {
    setconfirm(!confirm);
  }

  const getformdata = async (event) => {
    let inputvalue = event.target.value
    let inputname = event.target.name
    setMsg(false);
    // if (inputname === 'uname') {
    //   if (inputvalue.length >= 8 && formmode === '') {
    //     const user = { username: inputvalue, email : 'REGISTER' }
    //     const res = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/readbyusername`, user)
    //     if (res.data.length > 0) {
    //       setMsg(true);
    //     }
    //     else{
    //       console.log(res);
    //     }
    //   }
    // }
    setgetinputdata({ ...getinputdata, [inputname]: inputvalue })
    setinputerror({ ...inputerror, [inputname]: false })
  }

  const varNavigate = useNavigate()

  const checkUserExists = async () => {

    let username = getinputdata.uname

    if (username === '') {
      alert("UserName Should Be Given");
      inputRef.current.focus();
      return;
    }
    if (username.length < 8) {
      alert('Username Should Have Minimum Of 8 Characters');
      inputRef.current.focus();
      return;
    }
    const regex = /^[a-zA-Z0-9$@!#%&*\^\.]*$/;
    if (!regex.test(username)) {
      alert('Username Should Be Alphanumeric with Special Characters Only');
      inputRef.current.focus();
      return;
    }

    let email = getinputdata.email;
   
    if (email === '') {
      alert("Email Should Be Given");
      window.document.getElementById("email").select();
      window.document.getElementById("email").focus();
      return;
    }

    if (username !== "") {
      const xusername = getinputdata.uname.toLowerCase()
      if (xusername.toUpperCase() === "ADMIN" || xusername.toUpperCase() === 'RAINBOW') {
        alert("Invalid user name");
        inputRef.current.select();
        inputRef.current.focus();
        return;
      }
      const user = { username: username, email : 'REGISTER' };
      const res = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/readbyusername`, user)
      if (res.data.length > 0 && formmode !== 'FP') {
        alert("UserName already registered...");
        inputRef.current.select();
        inputRef.current.focus();
        return;
      }
      if (res.data.length === 0 && formmode === 'FP') {
        alert("UserName Does Not Exists");
        inputRef.current.select();
        inputRef.current.focus();
        return;
      }
      if (res.data.length > 0 && formmode === 'FP') {
        if (res.data[0].email.toLowerCase() !== getinputdata.email.toLowerCase()) {
          alert("Email Mismatch With Your UserName");
          window.document.getElementById("email").select();
          window.document.getElementById("email").focus();
          return;
        }
        if (res.data[0].password === getinputdata.password) {
          alert("Please Create New Password");
          window.document.getElementById("password").select();
          window.document.getElementById("password").focus();
          return;
        }

      }
    }
    console.log(getinputdata.mobile);
    if ((getinputdata.uname === "") || !(usernameRegex.test(getinputdata.uname))) {
      setinputerror((getmydata) => ({ ...getmydata, uname: true }))
    }
    else if ((getinputdata.email === "") || !(emailRegex.test(getinputdata.email))) {
      setinputerror((getmydata) => ({ ...getmydata, email: true }))
      setinputerror((getmydata) => ({ ...getmydata, uname: false }))
    }
    else if ((!getinputdata.mobile || getinputdata.mobile.length < 10)) {
      setinputerror((getmydata) => ({ ...getmydata, mobile: true }))
      setinputerror((getmydata) => ({ ...getmydata, email: false }))
    }
    else if ((getinputdata.password === "") || !(passwordRegex.test(getinputdata.password))) {
      setinputerror((getmydata) => ({ ...getmydata, password: true }))
      setinputerror((getmydata) => ({ ...getmydata, mobile: false }))
    }
    else if (getinputdata.confirm === "" || getinputdata.confirm !== getinputdata.password) {
      setinputerror((getmydata) => ({ ...getmydata, confirm: true }))
      setinputerror((getmydata) => ({ ...getmydata, password: false }))
    }
    else {
      runAPI()
    }
  };

  function formloginhandler() {
    varNavigate("/login")
  }

  async function runAPI() {
    try {
      const logindata = {
        username: getinputdata.uname,
        email: getinputdata.email,
        mobile: getinputdata.mobile,
        password: getinputdata.password,
        visitorID: visitorID,
        usertype: "GSTR9",
        doc : u.curdatetime(),
      }
      let url = `${process.env.REACT_APP_RAINBOW_URL}/create`;
      if (formmode === 'FP') {
        url = `${process.env.REACT_APP_RAINBOW_URL}/update_users`;
      }
      await axios.post(url, logindata)
        .then((res) => {
          alert(formmode === 'FP' ? "Successfully Updated" : "Successfully Registered");
          varNavigate("/login")
        })
        .catch((error) => {
          console.log(error)
        });
    }
    catch {

    }
  }

  const handleKeyPress = (event) => {
    // Allow only numbers (0-9)
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }


  return (
    <>
      <div className="Container">

        <div className="ContainerInner">

          <div className="innerrightsignin">

            <div className="headicon">
              <h3><span><SiGnuprivacyguard className="signupicon" /></span></h3>
            </div>

            <div className="inputfileds">

              <div className="inputs ">
                <label className="required">Username</label>
                <p>
                  <span><FaUser className="inputicon" /></span>
                  <input ref={inputRef} type="text" name="uname" onChange={getformdata} value={getinputdata.uname} maxLength={16} autoComplete='off' />
                </p>
                {inputerror.uname && <h5>Enter Username (having Minimum 6 Chars. and Maximum of 16 Chars.)</h5>}
                {existingUser && <h5 style={{ color: 'red' }}>User Already Found!</h5>}
              </div>

              <div className="inputs">
                <label className="required">Email</label>
                <p>
                  <span><MdEmail className="inputicon" /></span>
                  <input id="email" type="text" name="email" onChange={getformdata} value={getinputdata.email} autoComplete='off' style={{textTransform:"lowercase"}}/>
                </p>
                {inputerror.email && <h5>Enter Email Address</h5>}
              </div>

              <div className="inputs">
                <label className="required">Mobile No.</label>
                <p>
                  <span><FaMobileAlt className="inputicon" /></span>
                  <input id="mobile" type="text" name="mobile" onChange={getformdata} value={getinputdata.mobile} onKeyPress={handleKeyPress} autoComplete='off' maxLength={10}/>
                </p>
                {inputerror.mobile && <h5>Enter Mobile No.</h5>}
              </div>

              <div className="inputs">
                <label className="required">Password</label>
                <p>
                  <span><RiLockPasswordLine className="inputicon" /></span>
                  <input type={password ? "text" : "password"} id="password" name="password" maxLength={16} value={getinputdata.password} onChange={getformdata} />
                  {password ? (
                    <FaRegEye onClick={passwordhandler} className="eye" />
                  ) : (
                    <FaRegEyeSlash onClick={passwordhandler} className="eye" />
                  )}
                </p>
                {inputerror.password && <h5>Enter Password (having Minimum 6 Chars. and Maximum of 16 Chars., Must have one upperCase,one lowerCase,one Number and one Special Symbol [!@#$%*_.]) </h5>}
              </div>

              <div className="inputs">
                <label className="required">Confirm Password</label>
                <p>
                  <span><RiLockPasswordLine className="inputicon" /></span>
                  <input type={confirm ? "text" : "password"} name="confirm" onChange={getformdata} value={getinputdata.confirm}/>
                  {confirm ? (
                    <FaRegEye onClick={confirmpasswordhandler} className="eye" />
                  ) : (
                    <FaRegEyeSlash onClick={confirmpasswordhandler} className="eye" />
                  )}
                </p>
                {inputerror.confirm && <h5>Enter Confirm password</h5>}
              </div>

            </div>

            <div className="btn">
              <button onClick={formloginhandler}>
                <span>Login</span>
              </button>
              <button onClick={() => checkUserExists()}>
                <span>{formmode === 'FP' ? 'Update' : 'Register'}</span>
              </button>
            </div>
          </div>

        </div>

      </div>

    </>
  );
};

export default Signin;