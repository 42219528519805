import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import "./flash.css"

const Flashnews = () => {

  const [xvalue, setCurrentValue] = useState('');
  const [gstrvalue, setGstrValue] = useState('');
  const [xurl, setUrl] = useState('');
  const myNavigate = useNavigate()

  const firstinputRef = useRef(null)
  const urlRef = useRef(null);

  useEffect(() => {
    firstinputRef.current.focus();
  }, []);

  const defaultEnterRef = useRef(null)
  const defaultEscapeRef = useRef(null)

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        defaultEnterRef.current.click();
      }
      if (event.key === 'Escape') {
        defaultEscapeRef.current.click();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const configArray = [{id_name: 'GSTR9-Flash News', id_value: xvalue},{id_name:"GSTR9-Youtube URL", id_value: xurl},{id_name:"GSTR9Page-Flash News", id_value: gstrvalue}];
  const updateConfigValue = async (event) => {
    event.preventDefault();
   
    try {
      const data= {configArray};
      await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/updateConfigData`, data );
    } catch (error) {
      console.error('Error updating config value:', error);
    }
    myNavigate( "/emptypage")
    return;
  };

  const fetchConfigValue = async () => {
    try {
      const data = {configArray}
      const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/readConfigData`, data);
      setCurrentValue(response.data[0].id_name === 'GSTR9-Flash News' ? response.data[0].id_value : response.data[1].id_name === 'GSTR9-Flash News' ? response.data[1].id_value : response.data[2].id_value);
      setUrl(response.data[0].id_name === 'GSTR9-Youtube URL' ? response.data[0].id_value : response.data[1].id_name === 'GSTR9-Youtube URL' ? response.data[1].id_value : response.data[2].id_value);
      setGstrValue(response.data[0].id_name === 'GSTR9Page-Flash News' ? response.data[0].id_value : response.data[1].id_name === 'GSTR9Page-Flash News' ? response.data[1].id_value : response.data[2].id_value);
    } catch (error) {
      console.error('Error fetching config value:', error);
    }
  };

  useEffect(() => {
    fetchConfigValue();
  }, []);

  const handleInputChange = (event) => {
    const value = event.target.value.trim();
    setCurrentValue(value);
  };

  const handleInput2Change = (event) => {
    const value = event.target.value.trim();
    setGstrValue(value);
  };
  const handleUrlChange = (event) => {
    const value = event.target.value;
    setUrl(value);
  };
 


  return (
    <>
      <div className="mypageflashnews">
        <div className='mypagewindow'>
          <div className='popop'>

            <div className='pop1'>
              <h1>Flash News</h1>
              <textarea name="" id="" cols="30" rows="10"
                ref={firstinputRef}
                onChange={handleInputChange}
                value={xvalue}
                placeholder="Enter new value..."></textarea>

            </div>
            <div className='pop1'>
              <h1>Flash News(GSTR9)</h1>
              <textarea name="" id="" cols="30" rows="10"
                onChange={handleInput2Change}
                value={gstrvalue}
                placeholder="Enter new value..."></textarea>

            </div>
            <div className='urlclass'>
              <label htmlFor="">Youtube Url</label>
              <input type="text"

                id="url"
                ref={urlRef}
                onChange={handleUrlChange}
                value={xurl}
                placeholder="Enter URL..."></input>
            </div>
            <div className="pop11">
              <button onClick={() => myNavigate("/emptypage")} ref={defaultEscapeRef} >Go back</button>
              <button onClick={updateConfigValue} ref={defaultEnterRef}>Update</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Flashnews;