import React from 'react';
import Marquee from 'react-fast-marquee';
import "./mymarquee.css";
import { FaRegBell } from "react-icons/fa";
import { useState,useEffect } from 'react'; 
import axios from "axios";

const MyMarquee = () => {

    const [xvalue, setCurrentValue] = useState('');
    const configArray = [{id_name: 'GSTR9-Flash News', id_value: xvalue}];
    const fetchConfigValue = async () => {
      try {
        const data = {configArray}
        const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/readConfigData`, data);
        console.log(response);
        setCurrentValue(response.data[0].id_value);
      } catch (error) {
        console.error('Error fetching config value:', error);
      }
    };

    useEffect(() => {
        fetchConfigValue();
    },[]);
    

    return (
        <>
            <div className='mymarquee' >
                <FaRegBell className='bellicon'/> &nbsp; &nbsp;
                <Marquee velocity={25}>
                    <h3>{xvalue}</h3>
                </Marquee>
            </div>
        </>
    );
};

export default MyMarquee;