import "./License.css";
import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as u from '../utils/Common';

const LicenseForm = () => {
    const usernameRef = useRef();
    const [username, setusername] = useState("");
    const [email, setEmail] = useState("");
    const [gstr9license, setgstr9license] = useState(true);
    const [gstr1license, setgstr1license] = useState(false);
    const myNavigate = useNavigate();

    const handleUsernameChange = async (e) => {
        const value = e.target.value;
        setusername(value);
    }
    const handleEmailChange = async (e) => {
        const value = e.target.value;
        setEmail(value);
    }
    const handleGSTR9LicenseChange = (e) => {
        const value = e.target.checked;
        setgstr9license(value);
    };
    const handleGSTR1LicenseChange = (e) => {
        const value = e.target.checked;
        setgstr1license(value);
    };

    const removeuser = async () => {
        if (username === "") {
            alert("User Name Should be Given");
            return;
        }
        if (email === "") {
            alert("Email Should be Given");
            return;
        }
        const userdata = {
            username: username,
            email: email
        }
        const res = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/readbyusername`, userdata);
        if (!res.data.length > 0) {
            alert('User Not found Or Email ID May Be Wrong');
            usernameRef.current.focus();
            usernameRef.current.select();
            return;
        }
        const result = await Swal.fire({
            title: 'Are you sure to Remove the User -' + username + '?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        });

        if (result.isConfirmed) {
            const res = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/deleteuser`, userdata);
            if (res) {
                alert("User : " + username + " removed successfully ....")
                // myNavigate(-1)
                setusername('');
                setEmail('')
                setgstr1license(false);
            };
        }
    }

    const updateLicense = async () => {
        if (username === "") {
            alert("User Name Should be Given");
            return;
        }
        if (email === "") {
            alert("Email Should be Given");
            return;
        }
        if (username.toUpperCase() === 'ADMIN' || username.toUpperCase() === 'RAINBOW') {
            alert("Invalid User Name");
            return;
        }
        if (!gstr9license) {
            alert('GSTR9 License Should Be Choosen')
        }
        const userdata = {
            username: username,
            email: email
        }
        const res = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/readbyusername`, userdata);
        if (!res.data.length > 0) {
            alert('User Not found Or Email ID May Be Wrong');
            usernameRef.current.focus();
            usernameRef.current.select();
            return;
        }

        const data = {
            username: username,
            licensed: gstr9license ? 'Y' : 'D',
            licensed2: gstr1license ? 'Y' : 'D',
            dom: u.curdatetime(),
        }
        console.log(data);
        await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/LicenseUpdated`, data);
        alert("User : " + username + " activated successfully ....")
        setusername('');
        setEmail('')
        setgstr1license(false);
    }
    const defaultEnterRef = useRef(null);
    const defaultEscapeRef = useRef(null);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                defaultEnterRef.current.click();
            }
            if (event.key === 'Escape') {
                defaultEscapeRef.current.click();
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);
    return (
        <div className="licenseform-divmain">
            <div className="licenseform-container">
                <div className="licenseform-inputgroup1">
                    <label htmlFor="username" >Username</label>
                    <input type="text"
                        autoFocus={true}
                        autoComplete="off"
                        id="username"
                        ref={usernameRef}
                        value={username}
                        onChange={handleUsernameChange}
                        style={{ width: "75%", padding: "5px", fontSize: "16px" }} />
                </div>
                <div className="licenseform-inputgroup1">
                    <label htmlFor="email" >Email</label>
                    <input type="text"
                        autoComplete="off"
                        id="email"
                        value={email}
                        onChange={handleEmailChange}
                        style={{ width: "75%", padding: "5px", textTransform: "lowercase", fontSize: "16px" }} />
                </div>
                <label htmlFor="license" style={{ textDecoration: "underline", color: "green" }}>License</label>

                <div className="licenseform-inputgroup">
                    <label htmlFor="gstr9hsnlicense">GSTR9 HSN (Table 17)</label>
                    <input type="checkbox"
                        id="gstr9hsnlicense"
                        name="gstr9hsnlicense"
                        checked={gstr9license}
                        onChange={handleGSTR9LicenseChange}
                        style={{ marginLeft: "0" }} />

                </div>

                <div className="licenseform-inputgroup">
                    <label htmlFor="gstr9hsnlicense">GSTR1 HSN (Table 12)</label>
                    <input type="checkbox"
                        id="gstr1hsnlicense"
                        checked={gstr1license}
                        onChange={handleGSTR1LicenseChange}
                        style={{ marginLeft: "0" }} />

                </div>

                <div className="licenseform-btngroup">
                    <button onClick={removeuser}>Remove</button>
                    <button onClick={() => myNavigate(-1)} ref={defaultEscapeRef}>Cancel</button>
                    <button onClick={updateLicense} ref={defaultEnterRef}>Activate</button>
                </div>
            </div>
        </div>
    )
}
export default LicenseForm;