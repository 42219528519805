import React, { useState, useEffect, useRef } from 'react';
import './GSTR2B.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import JSZip from 'jszip';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaYoutube } from "react-icons/fa";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { type } from '@testing-library/user-event/dist/type';
import * as u from '../utils/Common';

const AgeGridForm = () => {

    const [videoUrl, setCurrentValue] = useState('');
    const configArray = [{ id_name: 'GSTR9-Youtube URL', id_value: videoUrl }];
    const fetchConfigValue = async () => {
        try {
            const data = { configArray }
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/readConfigData`, data);
            setCurrentValue(response.data[0].id_value);
        } catch (error) {
            console.error('Error fetching config value:', error);
        }
    };


    useEffect(() => {
        fetchConfigValue();
    }, []);

    let toolname = 'GSTR2B';

    const navigate = useNavigate();

    const gridRef = useRef();
    const [gstin, setgstin] = useState('');
    const json2BInputRef = useRef();
    let dataobj = ['b2b', 'b2ba', 'cdnr', 'cdnra'];

    const [existingData, setExistingData] = useState([]);
    const [typeofdata, setTypeofData] = useState('b2b');
    const [filePrd, setFilePrd] = useState('');

    const [b2bcdnrcolumnDefs] = useState([
        {
            headerName: 'Srl.',
            field: 'num',
            editable: false, headerClass: 'custom-header', cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            flex: 0.5,

        },
        { headerName: 'CTIN', headerClass: 'custom-header', field: 'ctin', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 1.3 },
        { headerName: 'Trade Name', headerClass: 'custom-header', field: 'trdnm', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 1.3 },
        {
            headerName: 'Bill No', headerClass: 'custom-header', field: 'inum', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 1,
            valueFormatter: (params) => {
                if (params.node.rowPinned) {
                    return "(" + String(params.value) + ")";
                }
            }
        },
        { headerName: 'Date', headerClass: 'custom-header', field: 'idt', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.8 },
        { headerName: 'Inv. Type', headerClass: 'custom-header', field: 'inv_typ', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.5 },
        { headerName: 'TAX(%)', headerClass: 'custom-header', field: 'rt', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.5 },
        {
            headerName: 'Taxable Value', headerClass: 'custom-header', field: 'txval', editable: false, flex: 1, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'IGST', headerClass: 'custom-header', field: 'iamt', editable: false, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'CGST', headerClass: 'custom-header', field: 'camt', editable: false, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'SGST', headerClass: 'custom-header', field: 'samt', editable: false, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'CESS', headerClass: 'custom-header', field: 'csamt', editable: false, flex: 0.7, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        { headerName: 'Source', headerClass: 'custom-header', field: 'source', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.4 },
    ]);

    const addNum = (data) => {
        return data.map((item, index) => {
            return {
                ...item,
                num: index + 1,
            };
        });
    };
    const [colors, setColor] = useState(false);
    const [jsonData, setJsonData] = useState({});
    const [json2AData, setJson2AData] = useState({});
    const [gstrtwiseBreakUp, setGstRtWiseBreakUp] = useState(false);
    const [rtExists, setrtExists] = useState(false);
    const gstrtwiseperiod = "202410";

    const resetAll = () => {
        setGstRtWiseBreakUp(false);
        setExistingData([]);
        setFilePrd('');
        setgstin('');
        setMonth2AArray([]);
        setrtExists(false);
    }

    const handleImport2BJson = async (event) => {
        const files = event.target.files[0];
        const fileName = files.name.toLowerCase();
        let parsedData = null;

        try {
            if (fileName.endsWith(".json")) {
                parsedData = await readJsonFile(files);
            } else if (fileName.endsWith(".zip")) {
                parsedData = await readJsonFromZip(files);
            } else {
                console.error("Unsupported file type. Please upload a .json or .zip file.");
                return;
            }
            if (parsedData) {
                resetAll();
                console.log(parsedData);
                setJsonData(parsedData.data.docdata);
                if (parsedData.data?.rtnprd) {
                    const date = parsedData.data.rtnprd;
                    const fp = date.substring(0, 2) + "/" + date.substring(2);
                    setFilePrd(fp);
                    const rtnperiod = date.substring(2) + date.substring(0, 2);
                    if (rtnperiod >= gstrtwiseperiod) {
                        setGstRtWiseBreakUp(true);
                    }
                }

                if (parsedData.data?.gstin) {
                    setgstin(parsedData.data.gstin);
                }
                pushData(parsedData.data.docdata);
            }

        } catch (error) {
            console.error("Error processing file:", error);
            return;
        }
        json2BInputRef.current.value = '';
    };
    const isEmpty = (data) => Object.keys(data).length === 0;
    let [Month2AArray, setMonth2AArray] = useState([]);
    const pushData = (Data) => {

        if (isEmpty(Data)) {
            return;
        }
        const parsedData = Data;
        let mergedData = [];

        if (typeofdata === 'b2b' || typeofdata === 'b2ba') {
            const data = typeofdata === 'b2b' ? parsedData.b2b : parsedData.b2ba;
            console.log(data);
            if (!data) {
                alert('There is no Data');
                setExistingData([]);
                return;
            }
            else {
                if (gstrtwiseBreakUp) {
                    data.forEach((b2bItem) => {
                        b2bItem.inv.forEach((item) => {
                            const mergeData = { ...item, trdnm: b2bItem.trdnm, ctin: b2bItem.ctin, inum: item.inum, billval: item.val, idt: item.dt, inv_typ: item.typ, iamt: item.igst ? item.igst : 0, camt: item.cgst ? item.cgst : 0, samt: item.sgst ? item.sgst : 0, csamt: item.cess ? item.cess : 0, source: item.srctyp ? item.srctyp : "--" }
                            mergedData.push(mergeData);
                        });
                    });

                }
                else {
                    data.forEach((b2bItem) => {
                        b2bItem.inv.forEach((invoice) => {
                            invoice.items.forEach((item) => {
                                const mergeData = { ...item, trdnm: b2bItem.trdnm, ctin: b2bItem.ctin, inum: invoice.inum, billval: item.val, idt: invoice.dt, inv_typ: invoice.typ, iamt: item.igst ? item.igst : 0, camt: item.cgst ? item.cgst : 0, samt: item.sgst ? item.sgst : 0, csamt: item.cess ? item.cess : 0, source: invoice.srctyp ? invoice.srctyp : "--" }
                                mergedData.push(mergeData);
                            });
                        });
                    });
                }
            }
        }
        if (typeofdata === 'cdnr' || typeofdata === 'cdnra') {
            const data = typeofdata === 'cdnr' ? parsedData.cdnr : parsedData.cdnra;
            if (!data) {
                alert('There is no Data');
                setExistingData([]);
                return;
            }
            else {
                data.forEach((cdnrItem) => {
                    cdnrItem.nt.forEach((nt) => {
                        nt.itms.forEach((item) => {
                            const mergeData = { ...item.itm_det, trdnm: cdnrItem.trdnm, ctin: cdnrItem.ctin, inum: nt.nt_num, billval: nt.val, idt: nt.nt_dt, inv_typ: nt.ntty, source: nt.srctyp ? nt.srctyp : "--" }
                            mergedData.push(mergeData);
                        });
                    });
                });
            }
        }

        const updateddata = addNum(mergedData);
        setExistingData(updateddata);
        checkFilePrd(updateddata);
    }

    const checkFilePrd = (Data) => {
        if (!Data) {
            return;
        }
        let dArray = [];
        Data.forEach((item) => {
            const fp = item.idt.substring(3, 5) + " / " + item.idt.substring(6);
            if (gstrtwiseBreakUp) {
                if (!dArray.includes(fp)) {
                    dArray.push(fp);
                }
            }
            if (fp !== filePrd) {
                setColor(true);
            }
        })
        setMonth2AArray(dArray);
    }

    useEffect(() => {
        if (!rtExists) {
            pushData(jsonData);
        }
    }, [typeofdata, gstrtwiseBreakUp])

    useEffect(() => {
        if (rtExists) {
            mergeData(gstr2AObj);
        }
    }, [typeofdata])
    const read2AJson = async (files, month) => {
        const fileName = files.name.toLowerCase();
        let parsedData = null;

        try {
            if (fileName.endsWith(".json")) {
                parsedData = await readJsonFile(files);
            } else if (fileName.endsWith(".zip")) {
                parsedData = await readJsonFromZip(files);
            } else {
                console.error("Unsupported file type. Please upload a .json or .zip file.");
                return;
            }
            if (parsedData) {
                setJson2AData(parsedData);
                if (parsedData?.fp) {
                    const date = parsedData.fp;
                    const fp = date.substring(0, 2) + " / " + date.substring(2);
                    if (fp !== month) {
                        return;
                    }
                }
            }

        } catch (error) {
            console.error("Error processing file:", error);
            return;
        }
        return parsedData;
    };

    const handleDataChange = (event) => {
        const value = event.target.value;
        setTypeofData(value);
    }

    const calculateTotals = () => {
        let totalTaxableValue = 0, totalIGST = 0, totalCGST = 0, totalSGST = 0, totalCESS = 0;

        existingData.forEach((row) => {
            totalTaxableValue += isNaN(row.txval) ? 0 : row.txval;
            totalIGST += isNaN(row.iamt) ? 0 : row.iamt;
            totalCGST += isNaN(row.camt) ? 0 : row.camt;
            totalSGST += isNaN(row.samt) ? 0 : row.samt;
            totalCESS += isNaN(row.csamt) ? 0 : row.csamt;
        });

        return [
            {
                hsn_sc: 'Total-->',
                ctin: 'Total-->',
                qty: String(existingData.length),
                inum: String(existingData.length),
                statename: 'Total-->' + " " + "(" + String(existingData.length) + ")",
                txval: totalTaxableValue,
                iamt: totalIGST,
                camt: totalCGST,
                samt: totalSGST,
                csamt: totalCESS,
            }
        ];
    };

    const [pinnedBottomRowData, setPinnedBottomRowData] = useState([]);

    useEffect(() => {
        const totals = calculateTotals();
        setPinnedBottomRowData(totals);
    }, [existingData]);

    const readJsonFile = async (file) => {
        try {
            const content = await file.text();
            const jsonData = JSON.parse(content);
            return jsonData;
        } catch (error) {
            console.error("Error reading JSON file:", error);
            throw error;
        }
    };
    const readJsonFromZip = async (file) => {
        try {
            const zip = await JSZip.loadAsync(file);
            // Create an array of promises to handle each file in the ZIP
            const jsonPromises = Object.keys(zip.files).map(async (relativePath) => {
                const zipEntry = zip.files[relativePath];

                if (zipEntry.name.endsWith(".json")) {
                    const content = await zipEntry.async("string"); // Read the JSON file as text
                    const jsonData = JSON.parse(content); // Parse the content into JSON
                    return jsonData;
                }
            });

            // Wait for all promises to resolve and filter out undefined results
            const jsonFiles = (await Promise.all(jsonPromises)).filter(Boolean);

            if (jsonFiles.length === 0) {
                throw new Error("No JSON files found in the ZIP");
            }

            return jsonFiles.length === 1 ? jsonFiles[0] : jsonFiles;
        } catch (error) {
            console.error("Error reading ZIP file:", error);
            throw error;
        }
    };

    const getRowStyle = params => {
        if (params.node.rowPinned) {
            return { backgroundColor: "#ccc", fontWeight: "600" }
        }
        if (colors) {
            const fp = params.data.idt.substring(3, 5) + "/" + params.data.idt.substring(6);
            if (fp !== filePrd) {
                return { backgroundColor: "pink" }
            }

        }
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState({});

    const toggleModal = () => {
        if (!existingData.length > 0) {
            alert("There is no Data in the Table")
            return;
        }
        setIsModalOpen(!isModalOpen);
    };
    const [gstr2AObj, setGSTR2AObj] = useState({});
    const handleFileChange = async (e, month) => {
        const file = e.target.files[0];
        const result = await read2AJson(file, month);
        if (!result) {
            alert("This file not Belongs to this Period");
            return;
        }

        setUploadedFiles((prev) => ({
            ...prev,
            [month]: file.name,
        }));
        setGSTR2AObj((prev) => ({
            ...prev,
            ...result, // Merge new result into the aggregated object
        }));

    };
    const allFilesUploaded = Month2AArray.every((month) => uploadedFiles[month]);

    const handleUploadOk = () => {
        if (!allFilesUploaded) {
            alert("Please Upload the Files");
            return;
        }
        alert("Files are Uploaded Successfully!");
        setIsModalOpen(false);
        mergeData(gstr2AObj);
    }

    const findInum = (b2bArray, targetInum) => {
        return b2bArray.find(item =>
            item.inv.some(invoice => invoice.inum === targetInum)
        );
    };

    const mergeData = (json2Adata) => {
        if (!json2Adata) {
            return;
        }
        let combinedData = [];
        let json2BArray = [];
        if (typeofdata === 'b2b') {
            jsonData.b2b.forEach((b2bItem) => {
                b2bItem.inv.forEach((item) => {
                    const mergeData = { ...item, trdnm: b2bItem.trdnm, ctin: b2bItem.ctin, inum: item.inum, billval: item.val, idt: item.dt, inv_typ: item.typ, iamt: item.igst ? item.igst : 0, camt: item.cgst ? item.cgst : 0, samt: item.sgst ? item.sgst : 0, csamt: item.cess ? item.cess : 0, source: item.srctyp ? item.srctyp : "--" }
                    json2BArray.push(mergeData);
                });
            });
            combinedData = json2BArray.map((item) => {
                const matchedItem = findInum(json2Adata.b2b, item.inum);
                if (matchedItem) {
                    const invoice = matchedItem.inv.find(invoice => invoice.inum === item.inum);
                    if (invoice) {
                        const mergedItems = invoice.itms.map((itm) => {
                            const itmDet = itm.itm_det;
                            return {
                                ...item, ...itmDet
                            };
                        }).flat();
                        return mergedItems;
                    }
                }
                return [item];
            }).flat();
        }
        const updatedData = addNum(combinedData);
        setrtExists(true);
        setExistingData(updatedData);
    };

    let FinancialYear = '';
    const intPrd = Number(filePrd.substring(4));
    if (filePrd.substring(0, 2) <= "03") {
        FinancialYear = (intPrd - 1) + "-" + filePrd.substring(5)
    }
    else {
        FinancialYear = filePrd.substring(4) + "-" + String((intPrd + 1)).substring(2);
    }

    
    const handleExportToExcel = async() => {
        if (existingData.length === 0) {
            alert("There is no Existing Data");
            return;
        }
        const fileMonth = u.getMonth("01/" + filePrd);
        console.log(fileMonth);
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Read me');
       
        const labelyearCell = worksheet.getCell('A4');
        labelyearCell.value = `Financial Year`;
        labelyearCell.alignment = { vertical: 'middle', horizontal: 'center' };
        labelyearCell.font = { bold: true };

        const valueyearCell = worksheet.getCell('C4');
        valueyearCell.value = `${FinancialYear}`;
        valueyearCell.alignment = { vertical: 'middle', horizontal: 'center' };
        valueyearCell.font = { bold: true };

        const labeltpCell = worksheet.getCell('A5');
        labeltpCell.value = `Tax Period`;
        labeltpCell.alignment = { vertical: 'middle', horizontal: 'center' };
        labeltpCell.font = { bold: true };

        const valuetpCell = worksheet.getCell('C5');
        valuetpCell.value = `${fileMonth}`;
        valuetpCell.alignment = { vertical: 'middle', horizontal: 'center' };
        valuetpCell.font = { bold: true };

        // const gstinCell = worksheet.getCell('A1');
        // gstinCell.value = `GSTIN: ${gstin.toUpperCase()}`;
        // gstinCell.alignment = { vertical: 'middle', horizontal: 'center' };
        // gstinCell.font = { bold: true };

    // const b2bSheet = workbook.addWorksheet("B2B");
    // b2bSheet.columns = [
    //     { header: "GSTIN", key: "GSTIN", width: 20 },
    //     { header: "Trade Name", key: "TradeName", width: 25 },
    //     { header: "Document Type", key: "DocumentType", width: 15 },
    //     { header: "Document Number", key: "DocumentNumber", width: 20 },
    //     { header: "Document Date", key: "DocumentDate", width: 15 },
    //     { header: "Taxable Value", key: "TaxableValue", width: 15 },
    //     { header: "IGST", key: "IGST", width: 10 },
    //     { header: "CGST", key: "CGST", width: 10 },
    //     { header: "SGST", key: "SGST", width: 10 },
    //     { header: "Cess", key: "Cess", width: 10 },
    // ];
    // b2bSheet.addRows(gstData.b2b);

    // // Create B2BA sheet
    // const b2baSheet = workbook.addWorksheet("B2BA");
    // b2baSheet.columns = [
    //     { header: "Original GSTIN", key: "OriginalGSTIN", width: 20 },
    //     { header: "Amended Document Number", key: "AmendedDocumentNumber", width: 25 },
    //     { header: "Original Document Date", key: "OriginalDocumentDate", width: 20 },
    //     { header: "Amended Date", key: "AmendedDate", width: 20 },
    //     { header: "Taxable Value", key: "TaxableValue", width: 15 },
    //     { header: "IGST", key: "IGST", width: 10 },
    //     { header: "CGST", key: "CGST", width: 10 },
    //     { header: "SGST", key: "SGST", width: 10 },
    //     { header: "Cess", key: "Cess", width: 10 },
    // ];
    // b2baSheet.addRows(gstData.b2ba);
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, "GSTR-2B.xlsx");
}
 
    //     worksheet.columns = [
    //         { key: 'num', width: 5 },
    //         { key: 'ctin', width: 15 },
    //         { key: 'trdnm', width: 10 },
    //         { key: 'inum', width: 10 },
    //         { key: 'inum', width: 10 },
    //         { key: 'billval', width: 10 },
    //         { key: 'txval', width: 15, style: { numFmt: '###,##,##,##0.00' } },
    //         { key: 'iamt', width: 10, style: { numFmt: '###,##,##,##0.00' } },
    //         { key: 'camt', width: 10, style: { numFmt: '###,##,##,##0.00' } },
    //         { key: 'samt', width: 10, style: { numFmt: '###,##,##,##0.00' } },
    //         { key: 'csamt', width: 10, style: { numFmt: '###,##,##,##0.00' } },
    //     ];

    //     headers.forEach((header, index) => {
    //         const cell = worksheet.getRow(4).getCell(index + 1);
    //         cell.font = { bold: true };
    //         cell.alignment = { horizontal: 'center' };
    //     });

    //     existingData.forEach((row, index) => {
    //         worksheet.addRow([
    //             index + 1,
    //             String(row.hsn_sc),
    //             row.qty,
    //             row.uqc.toUpperCase(),
    //             row.rt,
    //             parseFloat(row.txval),
    //             row.iamt,
    //             row.camt,
    //             row.samt,
    //             row.csamt,
    //         ]);
    //     });
   
    return (
        <div className='gstr2b-divmain'>
            <div className="gstr2b-formcontainer">
                <div className='gstr2b-header'>
                    <h6>{toolname}</h6>

                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <FaYoutube style={{ color: "#ff0000", fontSize: "28px", marginRight: "2px" }} onClick={() => window.open(videoUrl, "_blank", "noopener, noreferrer")} />
                        <AiOutlineClose onClick={() => navigate("/emptypage")} style={{ backgroundColor: "#ff0000", color: "white", fontSize: "18px" }} />
                    </div>
                </div>
                <div className='gstr2b-toppanel'>
                    <div className="input-group">
                        <select id='typeofdata'
                            name='typeofdata'
                            onChange={handleDataChange}
                            value={typeofdata}
                            style={{ width: "40%" }}>
                            <option value='' disabled selected>Choose</option>
                            {dataobj.map(data => (
                                <option key={data}
                                    value={data}>{data}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className='file-upload-div'>
                        <label htmlFor="file-upload-2b" style={{ paddingTop: "3px" }} className="file-upload-button" >Imp.GSTR2B JSON</label>
                        <input type="file" accept=".json, .zip" multiple={false} id="file-upload-2b"
                            onChange={handleImport2BJson}
                            style={{ width: "0", height: "22px", display: "none" }}
                            ref={json2BInputRef} />
                    </div>


                    <div className="input-group">
                        <label>Period:</label>
                        <input type='text' id='fp' value={filePrd}
                            style={{ width: "35%" }}
                            readOnly>

                        </input>
                    </div>
                    <div className="input-group">
                        <label>GSTIN:</label>
                        <input type="text" value={gstin} readOnly />
                    </div>
                    {gstrtwiseBreakUp &&
                        <div className='file-upload-div'>
                            <button onClick={toggleModal}>Imp.GSTR2A Json</button>
                        </div>
                    }
                    <div className='file-upload-div'>
                        <button onClick={handleExportToExcel}>Generate Excel</button>
                    </div>


                    {isModalOpen && (
                        <div className="GSTR2A-modal">
                            <div className="GSTR2A-modal-content">
                                <h2>Upload Files</h2>
                                {Month2AArray.map((month, index) => (
                                    <div key={month + index} className="GSTR2A-upload-container">
                                        <label>{month}</label>
                                        <input
                                            type="file"
                                            onChange={(e) => handleFileChange(e, month)}
                                            accept=".json, .zip" multiple={false}
                                            id={`upload-${month}`}
                                            style={{ display: "none" }}
                                            disabled={!!uploadedFiles[month]}
                                        />
                                        <button
                                            onClick={() => document.getElementById(`upload-${month}`).click()}
                                            className={`GSTR2A-upload-button ${uploadedFiles[month] ? "GSTR2A-uploaded" : ""}`}
                                        >
                                            {uploadedFiles[month] ? "Uploaded" : "Upload"}
                                        </button>

                                    </div>
                                ))}
                                <div className="GSTR2A-btngrp">
                                    <button onClick={toggleModal} className="cancel-btn" >Cancel</button>
                                    <button id='ok' onClick={handleUploadOk} className="ok-btn">OK</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className='gstr2b-container'>
                    <div className="gstr2b-agegrid ">
                        <div className="aag-theme-alpine" style={{ width: "100%", height: "100%" }}>
                            <AgGridReact
                                ref={gridRef}
                                rowHeight={25}
                                rowData={existingData}
                                columnDefs={b2bcdnrcolumnDefs}
                                domLayout="normal"
                                defaultColDef={{ resizable: true, flex: 1 }}
                                pinnedBottomRowData={pinnedBottomRowData}
                                getRowStyle={getRowStyle}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgeGridForm;
