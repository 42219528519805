import './App.css';
import Layout from './layout/Layout';
import React, { useEffect } from "react";


window.CopyRightCode = "\u00a9";


function App() {
  useEffect(() => {
    // Set the browser tab title
    document.title = "RAINBOW";
  }, []); // Empty dependency array ensures this runs only once
  
  return (
    <div className="App">
      <Layout/>
    </div>
  );
}

export default App;
